<template>
  <v-container>
    <h3 class="back"> 
      <router-link to="/"><v-icon>mdi-chevron-left</v-icon> <span>Volver al listado</span></router-link>
    </h3>
    <div class="comerce">
      <div class="comerce-image">
        <img :src="'https://app-api.acealguazas.es/public/foto/'+comerce.fotoPrincipal" />
      </div>
      <hr>
      <div class="comerce__info">
        <div class="comerce__info__categories">
          <span class="comerce__info__categories-category" v-for="category in comerce.categorias" :key="category.idCategoria">{{ category.nombre}}</span>
        </div>
        <div class="comerce__info-title">
          {{ comerce.nombre }}
        </div>
        <p class="comerce__info-subtitle" :html="comerce.descripcion">
        </p>
        <div class="comerce__info__contact">
          <div class="comerce__info__contact-phone"><v-icon>mdi-phone</v-icon> {{ comerce.telefono }}</div>
          <div class="comerce__info__contact-divider">
          <div class="comerce__info__contact-web" v-if="comerce.web"><a :href="comerce.web" target="_blank"><v-icon>mdi-web</v-icon></a></div>
          <div class="comerce__info__contact-email" v-if="comerce.email"><a :href="'mailto:'+comerce.email" target="_blank"><v-icon>mdi-email</v-icon></a></div>
          <div class="comerce__info__contact-twitter" v-if="comerce.twitter"><a :href="comerce.twitter" target="_blank"><v-icon>mdi-twitter</v-icon></a></div>
          <div class="comerce__info__contact-facebook" v-if="comerce.facebook"><a :href="comerce.facebook" target="_blank"><v-icon>mdi-facebook</v-icon></a></div>
          <div class="comerce__info__contact-instagram" v-if="comerce.instagram"><a :href="comerce.instagram" target="_blank"><v-icon>mdi-instagram</v-icon></a></div>
          </div>
        </div>
        <GmapMap
          :center="{lat:parseFloat(comerce.lat), lng:parseFloat(comerce.long)}"
          :zoom="17"
          style="width: 100%; height: 350px"
        >
          <GmapMarker
            :position="{lat:parseFloat(comerce.lat), lng:parseFloat(comerce.long)}"
          />
        </GmapMap>
      </div>
    </div>
  </v-container>
</template>

<script>

  export default {
    name: 'ComerceDetail',
    data: () => ({
    }),
    computed: {
      comerce() {
        return this.$store.getters.getComercioById(this.$route.params.id)
      }
    }
  }
</script>

<style lang="scss">
.back {
  margin-top: 25px;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
  a {
    color: grey !important;
    text-decoration: none;
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  i {
    vertical-align: text-top !important;
    color: #f48727 !important;
  }
}
.container {
  padding: 0 !important;
}
.comerce {
  margin: 40px 20px;
  display: flex;
  &-image {
    margin-right: 30px;
    width: 40%;
    img {
      max-width: 100%;
    }
  }
  hr {
    display: none;
  }
  &__info {
    width: 60%;
    margin-left: 30px;
    &__categories {
      margin-bottom: 12px;
      text-align: left;
      font-size: 14px;
      &-category {
        margin-right: 10px;
        border-radius: 20px;
        color: #777;
        padding: 6px 10px;
        background-color: rgba(#f48727, 0.25);
      }
    }
    &-title {
      font-size: 22px;
      font-weight: 500;
      text-align: left;
    }
    &-subtitle {
      font-size: 16px;
      text-align: left;
    }
    &__contact {
      margin-top: 15px;
      margin-bottom: 25px;
      display: flex;
      text-align: left;
      font-size: 18px;
      i {
        vertical-align: text-top !important;
        font-size: 26px !important;
      }
      &-phone {
        i {
          color: #4caf50 !important;
        }
      }
      &-web {
        i {
          color: #1976d2 !important;
        }
      }
      &-twitter {
        i {
          color: #00ACEE !important;
        }
      }
      &-facebook {
        i {
          color: #3B5998 !important;
        }
      }
      &-instagram {
        i {
          color: #8134AF !important;
        }
      }
      &-email {
        i {
          color: gray !important;
        }
      }
      a {
        text-decoration: none;
      }
      &-divider {
        display: flex;
        margin-left: auto;
        div {
          margin-left: 8px;
          transition: all ease-in-out 200ms;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    display: block;
    &-image {
      margin: 0;
      padding: 0;
      width: 100%;
      min-height: 200px;
      display: flex;
      img {
        max-height: 300px;
        margin: auto;
      }
    }
    hr {
      border: 1px solid #eee;
      display: block;
    }
    &__info {
      margin: 0;
      margin-top: 30px;
      width: 100%;
      padding: 0;
    }
  }
}
</style>
